// angular
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs'
// rxjs
import { filter, take } from 'rxjs/operators'
import { AppService } from 'src/app/app.service'
import {
  AcceptableBillingCountryCodes,
  AssignDevicesModalConfig,
  AvailableEndpointMetricsResponse,
  BillingAccount,
  BookingPackage,
  Device,
  DeviceInfo,
  Environments,
  IaSubscription,
  ImpulsePackagesResponse,
  ImpulsePackagesResponseItem,
  PCOverlayConfig,
  ProficloudInputConfig,
  SelectSubscriptionModalConfig,
  ServiceIds,
  ShoppingCart,
  SimplePackage,
  SubscriptionResponse,
  TsdMetric,
  TsdPackagesResponse,
  TsdPackagesResponseItem,
  TsdSubscription,
  UpgradePreview,
} from '../proficloud.interfaces'
// app
import { ChargeRepaySubscription, ChargeRepaySubscriptionResponse } from '@chargeRepay/charge-repay.interfaces'
import { TranslateService } from '@ngx-translate/core'
import { HttpBaseService } from '@services/http-base.service'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { DeviceBillingService, DeviceBillingSubscription } from '../../modules/device-management/services/device-billing.service'
import { DeviceService } from '../../modules/device-management/services/device.service'
import { DeviceStore } from '../../modules/device-management/stores/device.store'
import { EmmaHttpService } from '../../modules/energy-management/backend/emma-http.service'
import { AuthorisationService } from '../authorisation.service'
import { ContentService } from './../content/content.service'
import { ProficloudService } from './../proficloud.service'
import { ContentfulPlanVariant, ContentfulService } from './contentful-interfaces'

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  /**
   *    Streams
   */
  showBillingAddressRequired$ = new Subject<boolean>()

  showBillingAddressForm$ = new Subject<boolean>()

  showCheckoutForm$ = new Subject<boolean>()

  showConfirmationForm$ = new Subject<boolean>()

  showUpgradeConfirmationForm$ = new Subject<boolean>()

  showAuthRequired$ = new Subject<boolean>()

  showContactRequired$ = new Subject<boolean>()

  showUpgradeDetails$ = new Subject<boolean>()

  // assign devices or metric modals
  showAssignDevicesModal$ = new Subject<AssignDevicesModalConfig | false>()

  showAssignMetricsModal$ = new Subject<boolean | Device>()

  showSelectSubscriptionModal$ = new Subject<SelectSubscriptionModalConfig | false>()

  // cancellations
  showCancelSubscriptionConfirm$ = new Subject<
    | {
        subscription: TsdSubscription | IaSubscription | DeviceBillingSubscription | ChargeRepaySubscription | SubscriptionResponse // EMMA
        type: ServiceIds
      }
    | false
  >()

  tsdSubscriptionsListed$ = new BehaviorSubject<boolean>(false)

  subscriptionsListStarted$ = new Subject<boolean>()

  emsSubscriptionsListed$ = new Subject<boolean>()

  dmsBASubscriptionsListed$ = new Subject<boolean>()

  availableEndpointMetricsListed$ = new Subject<boolean>()

  previewIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  /**
   *    Data
   */

  links = {
    termsAndConditions: 'https://proficloud.io/terms-and-conditions/',
    softwareLicenseTerms: 'https://proficloud.io/software-license-terms-international/',
  }

  // tsd subscription (single)
  tsdSubscription: TsdSubscription

  tsdToalMetricsUsed = 0

  tsdFlatArray: TsdMetric[] = []

  bookedSubscription: SubscriptionResponse

  upgradedSubscription: SubscriptionResponse

  // impulseAnalytics (multiple)
  impulseAnalyticsSubscriptions: IaSubscription[] = []
  totalIASlots: number
  assignedIASlots: number

  // Emma Subscriptions (multiple)
  emsSubscriptions: SubscriptionResponse[]
  allEMMASubscriptions: SubscriptionResponse[]

  emmaSubscriptionsError = false

  // charge repay
  chargeRepaySubscriptions: ChargeRepaySubscription[] = []

  chargeRepayPackage: BookingPackage

  chargeRepayStats: { totalSeats: number; usedSeats: number; freeSeats: number }

  countryMapping: { [key in AcceptableBillingCountryCodes]: string } = {
    DE: 'Germany',
    CH: 'Switzerland',
    IT: 'Italy',
    ES: 'Spain',
    CL: 'Chile',
  }

  currentCountry: AcceptableBillingCountryCodes = 'DE'

  billingDetails: {
    shoppingCart?: ShoppingCart
    upgradePreview?: UpgradePreview
  } = {}

  upgradeService: ContentfulService

  /**
   *    loaded from billing service
   */
  billingAccount?: BillingAccount

  /**
   *   Dialog Config
   */

  billingAddressDialog: PCOverlayConfig = {
    title: 'Billing Data',
  }

  // fields
  billingFormPersonalFields: ProficloudInputConfig[]

  billingFormCompanyFields: ProficloudInputConfig[]

  // forom
  billingAddressForm: UntypedFormGroup

  availableIaPackages: ImpulsePackagesResponseItem[]

  availableTsdPackages: TsdPackagesResponseItem[]

  availableTsdPackagesListed$ = new Subject<boolean>()

  bookedTsdPackage?: TsdPackagesResponseItem

  emmaPackages: BookingPackage[]

  iaCapableDevices: Device[] = []

  tsdCapableDevices: Device[] = []

  dmsBaSubscriptions: SubscriptionResponse[]

  devicesListedSubscription: Subscription

  constructor(
    // angular
    public router: Router,
    // app
    public app: AppService,
    public authorization: AuthorisationService,
    public content: ContentService,
    private deviceService: DeviceService,
    private deviceStore: DeviceStore,
    public proficloud: ProficloudService,
    private statusOverlay: PcStatusOverlayService,
    public deviceBilling: DeviceBillingService,
    private httpBase: HttpBaseService,
    private emmaHttp: EmmaHttpService,
    private translationService: TranslateService
  ) {
    // for developing
    ;(window as any).billing = this

    // do nothing for non-authenticated users
    if (!proficloud.keycloakData.session) {
      return
    }
    this.listAvailableTsdPackages()
    this.listAvailableImpulsePackages()

    // Case 1: Devices already loaded
    if (!this.deviceStore.storeInitialised$.getValue()) {
      this.fetchSubscriptions()
    }
    // Case 2: Devices not loaded yet. Waiting for Observable
    else {
      // Note: First is "errored" because the initial value which needs to be sent and second will be the actual value.
      // After this we don't care anymore. If we don't do this then org switch causes this to fire again which sends out multiple
      // calls to fetchSubscriptions() and other observables being fired.
      // This is clearly a load of spaghetti and the real solution is to refactor this service into a proper store but until then...
      this.deviceStore.storeInitialised$.pipe(take(2)).subscribe((errored) => {
        if (!errored) {
          // here
          this.fetchSubscriptions()
        }
      })
    }

    this.setupReactionToDevicesAndSubscriptionsListed()

    this.setupBilling()

    // create form
    this.content.countriesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
      this.createBillingAddressForm()
    })

    // subscribe to service content loaded.
    this.content.servicesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
      this.onServiceContentLoaded()
    })

    // listen for checkout modal -> write to data layer
    this.reactToCheckoutModal()

    this.proficloud.organisationSwitched$.subscribe(() => {
      this.setupBilling()
      // here
      this.fetchSubscriptions()
    })

    this.proficloud.organisationSwitchBegun$.subscribe(() => {
      this.clearSubscriptions()
    })
  }

  private setupBilling() {
    // get billing account
    this.getBillingAccount().subscribe({
      next: (billingAccount) => {
        this.billingAccount = billingAccount
        this.currentCountry = billingAccount.address.country
      },
      error: (error: HttpErrorResponse) => {
        this.proficloud.logoutOnUnauthorised(error)
        if (error.status === 404) {
          delete this.billingAccount
        }

        // Exception: Billing account not found: HttpStatus = NOT FOUND
        console.log('billing account error', error)
      },
      complete: () => {
        this.content.countriesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
          this.createBillingAddressForm()
        })
      },
    })
  }

  private listAndProcessTsdSubscriptions() {
    // try to get a tsd subscription. with it, add used and available metrics to each device
    this.listTsdSubscriptions().subscribe({
      next: (subscriptions: TsdSubscription[]) => {
        this.tsdSubscription = subscriptions[0]

        if (this.tsdSubscription) {
          this.setAndCountTsdMetrics()
        }

        this.tsdSubscriptionsListed$.next(true)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        console.log('list subscriptions error', error)
        // Exception: Billing account not found: HttpStatus = NOT FOUND
      },
    })
  }

  public fetchSubscriptions() {
    this.subscriptionsListStarted$.next(true)

    // make an arrays of capabale devices
    this.iaCapableDevices = this.deviceService.devices.filter((device) => this.deviceService.deviceIsIaCapable(device)) || []
    this.tsdCapableDevices = this.deviceService.devices.filter((device) => this.deviceService.deviceIsTsdCapable(device)) || []

    // initially, set all tsd capabale device's used and available metrics to an empty array.
    this.deviceService.devices
      ?.filter((device) => this.deviceService.deviceIsTsdCapable)
      .forEach((device) => {
        device.availableMetrics = []
      })

    this.listAndProcessTsdSubscriptions()

    // try to get impulse analytics subscriptions!
    this.listIaSubscriptions().subscribe({
      next: (r: IaSubscription[]) => {
        if (r && r.length > 0) {
          this.impulseAnalyticsSubscriptions = r
          this.totalIASlots = r.map((sub) => sub.quantity).reduce((a, b) => a + b, 0)
          this.assignedIASlots = r.map((sub) => sub.devicesUsed.length).reduce((a, b) => a + b)
        } else {
          this.impulseAnalyticsSubscriptions = []
          this.totalIASlots = 0
          this.assignedIASlots = 0
        }
      },
      error: (error: HttpErrorResponse) => {
        this.proficloud.logoutOnUnauthorised(error)
        console.error('list ia sub error', error)
      },
    })

    this.emsSubscriptions = []

    // Get Emma subscriptions
    this.emmaHttp.getEmsSubscriptions().subscribe({
      next: (subscriptions) => {
        this.allEMMASubscriptions = subscriptions
        this.emsSubscriptions = subscriptions.filter((s) => s.smartServiceId === 'ems')
        this.emsSubscriptionsListed$.next(true)
      },
      error: (error: HttpErrorResponse) => {
        // Note: Set
        this.emsSubscriptions = []
        this.emsSubscriptionsListed$.next(true)
        this.proficloud.logoutOnUnauthorised(error)
      },
    })

    this.listPackagesPerService('ems').subscribe((packages) => {
      this.emmaPackages = packages
    })

    this.listPackagesPerService('crs').subscribe((r) => {
      this.chargeRepayPackage = r[0]
    })

    this.listChargeRepaySubscriptions().subscribe({
      next: (r) => {
        this.chargeRepaySubscriptions = r
        this.chargeRepayStats = this.chargeRepaySubscriptions.reduce(
          (acc, curr) => {
            acc.totalSeats += curr.seatsMaxCount
            acc.freeSeats += curr.seatsFreeCount
            acc.usedSeats += curr.seatsUsed.length
            return acc
          },
          { totalSeats: 0, freeSeats: 0, usedSeats: 0 }
        )
      },
    })
    this.listDmsBaSubscriptions()
  }

  listDmsBaSubscriptions() {
    // TODO: Replace this with specific call to dms subscriptions
    this.listAllSubscriptions().subscribe((subs) => {
      this.dmsBaSubscriptions = subs.filter((sub) => {
        return sub.smartServiceId == 'dmsAddonBasic'
      })
      this.dmsBASubscriptionsListed$.next(true)
    })
  }

  private clearSubscriptions() {
    this.emsSubscriptions.length = 0
  }

  private setupReactionToDevicesAndSubscriptionsListed() {
    combineLatest([this.deviceStore.storeInitialised$, this.tsdSubscriptionsListed$, this.availableTsdPackagesListed$]).subscribe(() => {
      if (!this.tsdSubscription) {
        return
      }

      // there should always be exactely 1 tsd subscription and thus a package always
      const packId = this.tsdSubscription.bookedPackageId
      this.bookedTsdPackage = this.availableTsdPackages.find((pack) => pack.id === packId)
    })
  }

  setAllAvailableEndpointMetrics() {
    const assignMetrics = () => {
      this.deviceService.devices
        ?.filter((device) => this.deviceService.deviceIsTsdCapable(device))
        .forEach((device) => {
          this.listAvailableEndpointMetrics(device).subscribe({
            next: (res) => {
              // write available metrics onto device
              device.availableMetrics = res[device.endpointId]
            },
            error: (err: HttpErrorResponse) => this.proficloud.logoutOnUnauthorised(err),
          })
        })
    }

    if (!this.deviceService.devices) {
      this.deviceStore.storeInitialised$.subscribe((errored) => {
        if (!errored) {
          assignMetrics()
        }
      })
    } else {
      assignMetrics()
    }
  }

  setAndCountTsdMetrics() {
    this.tsdToalMetricsUsed = 0
    this.tsdFlatArray = []
    // Resets the used Metrics of all devices. For when all metrics of a device get unassigned. This won´t get updated otherwise.
    this.deviceService.devices.forEach((device) => {
      device.usedMetrics = []
    })
    this.tsdSubscription.metricsUsed.forEach((deviceObject) => {
      Object.entries(deviceObject).forEach(([endpointId, metrics]) => {
        const device = this.deviceService.devices.find((d) => d.endpointId === endpointId)
        if (!device) {
          console.warn('this device is used in a subscription but is not found in this account', endpointId)
          return
        }

        if (!device.metadata.uuid) {
          console.warn('device has no UUID')
          return
        }

        device.usedMetrics = metrics
        this.tsdToalMetricsUsed += metrics.length

        metrics.forEach((metric) => {
          this.tsdFlatArray.push({
            deviceName: device.metadata.deviceName,
            uuid: device.metadata.uuid as string,
            metric,
            device,
          })
        })
      })
    })

    // Set userMetrics to an empty array if not present to avoid possible interface errors later (not ideal)
    this.deviceService.devices.forEach((device) => {
      if (!device.usedMetrics) {
        device.usedMetrics = []
      }
    })
  }

  reactToCheckoutModal() {
    this.showCheckoutForm$.pipe(filter((active) => !!active)).subscribe(() => {
      const win: any = window
      win.dataLayer = win.dataLayer || []
      win.dataLayer.push({
        event: 'purchase',
        transactionId: new Date().getTime() + '',
        transactionTotal: this.billingDetails.shoppingCart?.plan?.fields.price,
        transactionProducts: [
          {
            sku: this.billingDetails.shoppingCart?.plan?.fields.sapNumber,
            name: this.billingDetails.shoppingCart?.plan?.fields.planVariantName,
            category: this.billingDetails.shoppingCart?.plan?.fields.planName,
            price: this.billingDetails.shoppingCart?.plan?.fields.price,
            quantity: this.billingDetails.shoppingCart?.quantity || 1,
          },
        ],
      })
    })
  }

  getCountryTerms() {
    const entry = this.content.content.countryTerms?.find((terms) => terms.fields.countryCode === this.billingAccount?.address.country)
    if (!entry) {
      console.warn('Entry not found')
      return
    }

    return {
      entityName: entry.fields.downloadTitle,
      termsUrl: entry.fields.downloads.fields.file.url,
    }
  }

  getContractPartner() {
    const contractPartner = this.content.content.contractPartners?.find((partner) => partner.fields.countryCode === this.billingAccount?.address.country)
    if (!contractPartner) {
      console.warn('Contract partners not found')
      return
    }
    return contractPartner.fields
  }

  createBillingAddressForm() {
    this.billingFormPersonalFields = []

    // pre-filled value
    let email = this.billingAccount?.emailAddress ? this.billingAccount?.emailAddress : this.proficloud.keycloakData.userDetails?.data.email
    let firstName = this.billingAccount?.firstName ? this.billingAccount?.firstName : this.proficloud.keycloakData.userDetails?.data.firstName
    let lastName = this.billingAccount?.lastName ? this.billingAccount?.lastName : this.proficloud.keycloakData.userDetails?.data.lastName

    // if street and houseNumber exists
    let street = this.billingAccount?.address.street ? this.billingAccount?.address.street + ' ' : ''
    let houseNumber = this.billingAccount?.address.houseNumber ? this.billingAccount?.address.houseNumber : ''

    this.billingFormCompanyFields = [
      {
        type: 'text',
        placeholder: 'Email',
        key: 'email',
        // helpText: 'e.g. john@doe.com',
        control: new UntypedFormControl(email || '', [Validators.required, Validators.email]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_EMAIL_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'First Name',
        key: 'firstname',
        // helpText: 'e.g. John',
        control: new UntypedFormControl(firstName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_FIRST_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Last Name',
        key: 'lastname',
        // helpText: 'e.g. Doe',
        control: new UntypedFormControl(lastName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_LAST_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Company Name',
        key: 'companyname',
        // helpText: 'e.g. ACME Inc.',
        control: new UntypedFormControl(this.billingAccount?.companyName || '', [Validators.required, Validators.maxLength(80)]),
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_COMPANY_NAME_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Address Line 1',
        key: 'addressLine1',
        // helpText: 'e.g. Harvard Blvd.',
        control: new UntypedFormControl(this.billingAccount?.address.addressLine1 ?? street + houseNumber, [Validators.required, Validators.maxLength(64)]),
        maxLength: 64,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_ADDRESS_LINE_1_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Address Line 2',
        key: 'addressLine2',
        // helpText: 'e.g. 144C',
        control: new UntypedFormControl(this.billingAccount?.address.addressLine2 ?? '', [Validators.maxLength(64)]),
        maxLength: 64,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_ADDRESS_LINE_2_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Postal Code',
        key: 'postal',
        // helpText: 'e.g. 10119',
        control: new UntypedFormControl(this.billingAccount?.address.postalCode || '', [Validators.required]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_POSTAL_CODE_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'City',
        key: 'city',
        // helpText: 'e.g. Youngstown',
        control: new UntypedFormControl(this.billingAccount?.address.city || '', [Validators.required]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_CITY_VALIDATION'),
      },
      {
        type: 'select',
        selectValues: this.content?.content?.contractPartners?.map((country) => {
          return {
            key: country.fields.countryCode,
            value: country.fields.country,
            visible: () => this.isCountryAvailable(country.fields.countryCode),
          }
        }),
        /* selectValues: [
          { key: 'DE', value: 'Germany', visible: () => true },
          { key: 'CH', value: 'Switzerland', visible: () => true },
          { key: 'ES', value: 'Spain', visible: () => true },
          { key: 'IT', value: 'Italy', visible: () => true },
          { key: 'CL', value: 'Chile', visible: () => true },
        ], */
        placeholder: 'Country',
        value: '',
        control: new UntypedFormControl(this.billingAccount?.address.country || '', [Validators.required]),
        key: 'country',
        inputId: 'country',
        // helpText: 'If your country is not available, please contact us.',
        changed: () => {
          // console.log('land changed')
        },
      },
      {
        type: 'text',
        placeholder: 'VAT number',
        key: 'vatNumber',
        // helpText: 'e.g. DE123456789, CHE-123.456.789',
        control: new UntypedFormControl(this.billingAccount?.vatId || '', [Validators.required, Validators.pattern(this.vatPattern)]),
        maxLength: 80,
        errorHint: this.translationService.instant('USR_SETTINGS.BAFIE_VAT_NUM_VALIDATION'),
      },
      {
        type: 'text',
        placeholder: 'Phoenix Contact Customer ID',
        key: 'pxcCustomerId',
        // helpText: 'e.g. PQRSTXYZ',
        control: new UntypedFormControl(this.billingAccount?.pxcCustomerId || '', [
          // Validators.pattern(this.vatPattern),
        ]),
        maxLength: 80,
      },
    ]

    const formObject: Record<string, UntypedFormControl> = {}
    ;[...this.billingFormCompanyFields, ...this.billingFormPersonalFields].forEach((element) => {
      formObject[element.key] = element.control
    })

    // set prefillable values
    if (!this.billingAccount) {
      formObject['companyname'].setValue(this.proficloud.currentOrganisation?.organizationName || '')
      formObject['firstname'].setValue(this.proficloud.keycloakData?.userDetails?.data?.firstName || '')
      formObject['lastname'].setValue(this.proficloud.keycloakData?.userDetails?.data?.lastName || '')
    }

    this.billingAddressForm = new UntypedFormGroup(formObject)
  }

  isCountryAvailable(countryCode: string) {
    const keyMap: { [key in Environments]: 'showOnDevelopment' | 'showOnProduction' } = {
      local: 'showOnDevelopment',
      dev: 'showOnDevelopment',
      staging: 'showOnDevelopment',
      production: 'showOnProduction',
    }
    const key = keyMap[this.app.environment.frontend]
    const available = this.content.content.contractPartners?.find((country) => {
      return country.fields.countryCode === countryCode && country.fields[key]
    })
    return available ? true : false
  }

  spanishVAT = '^ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$'

  germanVAT = '^(DE)[0-9]{9}$'

  italianVAT = '^(IT)[0-9]{11}$'

  swissVAT1 = '^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3}$'

  chileVAT = '^.*$'

  chinaVAT = '^.*$'
  // swissVAT2 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (MWST)$"
  // swissVAT3 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (TVA)$"
  // swissVAT4 = "^(CHE)-[0-9]{3}.[0-9]{3}.[0-9]{3} (IVA)$"

  vatPattern = [this.spanishVAT, this.germanVAT, this.italianVAT, this.swissVAT1, this.chileVAT, this.chinaVAT].join('|')

  orderReferenceControl = new UntypedFormControl()

  referenceInput = ''

  upgradeReferenceControl = new UntypedFormControl()

  upgradeReference = ''

  onServiceContentLoaded() {
    // console.log('billing service say services loaded!', this.content.content.services)
  }

  /**    BILLING ACCOUNT   */

  /**
   *    Retrieve a user's billing account (using their access token)
   */
  getBillingAccount() {
    const url = this.httpBase.backendUrls.billingBaseUrl + '/billingAccount'
    return this.proficloud.http.get<BillingAccount>(url)
  }

  getCountryLanguageCode(countryCode: string) {
    const country = this.content.content.contractPartners?.find((country) => country.fields.countryCode === countryCode)
    if (!country) {
      console.warn('Country not found')
      return
    }
    console.log('setting lang to ', country.fields.languageCode)
    return country ? country.fields.languageCode : 'en'
  }

  /**
   *    Create new BillingAccount for Proficloud user
   */
  createBillingAccount() {
    const billingAccountData: BillingAccount = {
      firstName: this.billingAddressForm.value.firstname,
      lastName: this.billingAddressForm.value.lastname,
      language: this.getCountryLanguageCode(this.billingAddressForm.value.country) || 'en-EN',
      // This should be prefilled with the organisation name if available
      // Companyname should also be renamed to Organization, this will happen once the billing service got refactored
      companyName: this.billingAddressForm.value.companyname,
      emailAddress: this.billingAddressForm.value.email,
      vatId: this.billingAddressForm.value.vatNumber,
      pxcCustomerId: this.billingAddressForm.value.pxcCustomerId,
      address: {
        street: this.billingAddressForm.value.street,
        houseNumber: this.billingAddressForm.value.houseNumber,
        addressLine1: this.billingAddressForm.value.addressLine1,
        addressLine2: this.billingAddressForm.value.addressLine2,
        city: this.billingAddressForm.value.city,
        postalCode: this.billingAddressForm.value.postal,
        country: this.billingAddressForm.value.country,
      },
    }

    const createStatus$ = new Subject<boolean>()

    // show 'busy' overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.createBillingAccountBusy)

    // timeout mechanics
    const timedOut$ = new Subject<void>()
    setTimeout(() => {
      timedOut$.next()
    }, 10_000)

    // fire off the create request
    const url = this.httpBase.backendUrls.billingBaseUrl + '/billingAccount'
    const subscription = this.proficloud.http.post<BillingAccount>(url, billingAccountData).subscribe({
      next: (billingAccount) => {
        // update local billing account
        this.billingAccount = billingAccount
        this.currentCountry = billingAccount.address.country

        // discard timeout subject
        timedOut$.complete()

        // show create-success message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.createBillingAccountSuccess)

        // close billing form dialog
        this.showBillingAddressForm$.next(false)

        // close success dialog after 2 seconds
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          createStatus$.next(true)
        }, 2000)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        // show update error message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.createBillingAccountError)
        createStatus$.next(true)
      },
    })

    // cancel request if it times out
    timedOut$.subscribe(() => {
      this.statusOverlay.showStatus(this.proficloud.statusOverlays.createBillingAccountTimeout)
      subscription.unsubscribe()
    })

    return createStatus$
  }

  /**
   *    Update a user's billing account details
   */
  updateBillingAccount() {
    const billingAccountData: BillingAccount = {
      firstName: this.billingAddressForm.value.firstname,
      lastName: this.billingAddressForm.value.lastname,
      language: this.getCountryLanguageCode(this.billingAddressForm.value.country) || 'en-EN',
      companyName: this.billingAddressForm.value.companyname,
      emailAddress: this.billingAddressForm.value.email,
      vatId: this.billingAddressForm.value.vatNumber,
      pxcCustomerId: this.billingAddressForm.value.pxcCustomerId,
      address: {
        street: this.billingAddressForm.value.street,
        houseNumber: this.billingAddressForm.value.houseNumber,
        addressLine1: this.billingAddressForm.value.addressLine1,
        addressLine2: this.billingAddressForm.value.addressLine2,
        city: this.billingAddressForm.value.city,
        postalCode: this.billingAddressForm.value.postal,
        country: this.billingAddressForm.value.country,
      },
    }

    // show 'busy' overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateBillingAccountBusy)

    // fire off the update request
    const url = this.httpBase.backendUrls.billingBaseUrl + '/billingAccount'
    this.proficloud.http.put<BillingAccount>(url, billingAccountData).subscribe({
      next: (billingAccount) => {
        // update local billing account
        this.billingAccount = billingAccount
        this.currentCountry = billingAccount.address.country

        // close address form
        this.showBillingAddressForm$.next(false)

        // show update-success message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateBillingAccountSuccess)

        // Rebuild billing address form with updated values
        this.createBillingAddressForm()

        // close success dialog after 2 seconds
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        // show update error message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateBillingAccountError)
      },
    })
  }

  /**
   *   Delete a user's biling account
   */
  deleteBillingAccount() {
    // show 'busy' overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateBillingAccountBusy)

    // fire of the delete request
    const url = this.httpBase.backendUrls.billingBaseUrl + '/billingAccount'
    this.proficloud.http.delete(url).subscribe({
      next: () => {
        // update local billing account
        this.billingAccount = undefined

        // show delete-success message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.deleteBillingAccountSuccess)

        // close success dialog after 2 seconds
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        // show delete error message
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.deleteBillingAccountError)
      },
    })
  }

  /**     SUBSCRIPTIONS     */

  /**
   *    Subscribe a user to a package
   */
  createSubscription(simplePackage: SimplePackage) {
    simplePackage.internalOrderId = this.orderReferenceControl.value

    const success$ = new Subject<boolean>()

    // show busy overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionBusy)

    // fire off create-subscription call
    const url = this.httpBase.backendUrls.billingBaseUrl + '/subscriptions'
    this.proficloud.http.post<SubscriptionResponse>(url, simplePackage).subscribe({
      next: (bookedSubscription) => {
        // store booke subscription locall
        this.bookedSubscription = bookedSubscription

        // show success
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionSuccess)

        // in case of tsd, reload tsd subscription from tsd service (--> improve: only do it for TSD)
        this.listTsdSubscriptions().subscribe((subscriptions: TsdSubscription[]) => {
          this.tsdSubscription = subscriptions[0]
          console.log('set tsd subscription after purchase!')

          if (this.tsdSubscription) {
            this.setAndCountTsdMetrics()
          }
        })

        // close success dialog after 2 seconds
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)

        // broadcast success
        success$.next(true)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        // show error
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionError)

        success$.next(false)
      },
    })

    return success$
  }

  /**
   *    Subscription update (test only so far)
   */
  upgradeSubscription(subscriptionId: string = this.tsdSubscription.id, bookingPackage: SimplePackage = { packageId: '1282581', quantity: 1 }) {
    // clear reference
    this.upgradeReference = ''

    const url = this.httpBase.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}/upgrade`

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionBusy)

    this.proficloud.http.post(url, bookingPackage).subscribe({
      next: (response: SubscriptionResponse) => {
        // store booke subscription locally
        this.upgradedSubscription = response

        // re-load tsd subscriptions
        this.listAndProcessTsdSubscriptions()

        // hide preview
        this.showUpgradeDetails$.next(false)

        // show sucess
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionSuccess)

        // after 2 seconds, hide success and show upgrade confirmation modal
        setTimeout(() => {
          this.statusOverlay.resetStatus()
          this.showUpgradeConfirmationForm$.next(true)
        }, 2000)

        // console.log('successfull upgraded a subscription!', response)
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionError)
        // console.warn('error upgrading your subscription', err)
      },
    })
  }

  downgradeSubscription(subscriptionId: string = this.tsdSubscription.id, bookingPackage: SimplePackage = { packageId: '1246361', quantity: 1 }) {
    const url = this.httpBase.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}/downgrade`

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.downgradeSubscriptionBusy)

    this.proficloud.http.post(url, bookingPackage).subscribe({
      next: (response) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.downgradeSubscriptionSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
        console.log('successfull downgraded a subscription!', response)
      },
      error: (err) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.downgradeSubscriptionError)
        console.warn('error downgrading your subscription', err)
      },
    })
  }

  /**
   *    Subscription update PREVIEW (test only so far)
   */
  upgradeSubscriptionPreview(
    service: ContentfulService,
    subscriptionId: string = this.tsdSubscription.id,
    bookingPackage: SimplePackage = { packageId: '1282582', quantity: 1 }
  ) {
    this.previewIsLoading$.next(true)
    const url = this.httpBase.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}/upgrade/preview`
    this.proficloud.http.post(url, bookingPackage).subscribe({
      next: (response: UpgradePreview) => {
        this.billingDetails.upgradePreview = response
        this.upgradeService = service
        this.showUpgradeDetails$.next(true)
        this.previewIsLoading$.next(false)
      },
      error: (err) => {
        this.previewIsLoading$.next(false)
        console.warn('error getting upgrade preview', err)
      },
    })
  }

  /**
   *    Add subscription to cart
   */
  addSubscriptionToCart(options: { service: ContentfulService; plan: ContentfulPlanVariant; quantity: number }) {
    this.billingDetails.shoppingCart = options
  }

  goToService() {
    this.showConfirmationForm$.next(false)
    // navigate to service
  }

  /**
   *    AVAILABLE TSD packages (via tsd service)
   */
  listAvailableTsdPackages() {
    const url = this.httpBase.backendUrls.tsdServiceUrl + '/billing/packages'
    this.proficloud.http.get<TsdPackagesResponse>(url).subscribe({
      next: (r) => {
        this.availableTsdPackages = r
        this.availableTsdPackagesListed$.next(true)
      },
      error: (err) => this.proficloud.logoutOnUnauthorised(err),
    })
  }

  /**
   *    AVAILABLE IA packages (via ia service)
   */
  listAvailableImpulsePackages() {
    const url = this.httpBase.backendUrls.iaServiceUrl + '/billing/packages'
    this.proficloud.http.get<ImpulsePackagesResponse>(url).subscribe({
      next: (r) => {
        this.availableIaPackages = r
      },
      error: (err) => this.proficloud.logoutOnUnauthorised(err),
    })
  }

  /**
   *    BOOKED TSD subscription(s) [only one expected]
   */
  listTsdSubscriptions() {
    const url = this.httpBase.backendUrls.tsdServiceUrl + '/billing/subscriptions'
    return this.proficloud.http.get<TsdSubscription[]>(url)
  }

  /**
   *    BOOKED IA subscriptions
   */
  listIaSubscriptions() {
    const url = this.httpBase.backendUrls.iaServiceUrl + '/billing/subscriptions'
    return this.proficloud.http.get<IaSubscription[]>(url)
  }

  /**
   *    Augment devices with available metrics (via tsd service)
   */
  listAvailableEndpointMetrics(device: DeviceInfo) {
    const url = this.httpBase.backendUrls.tsdServiceUrl + '/billing/available/metrics/' + device.endpointId
    return this.proficloud.http.get<AvailableEndpointMetricsResponse>(url)
  }

  /**
   *    User action: set used TSD metrics (via tsd service)
   *    -> this is probably outdated, remove once modal is available also at device level
   */
  setUsedMetrics(subscriptionId: string, endpointId: string, metrics: string[]) {
    const url = this.httpBase.backendUrls.tsdServiceUrl + '/billing/used/metrics/' + subscriptionId

    const endpointDict: Record<string, any> = {}
    endpointDict[endpointId] = metrics

    const payload = { metrics: [endpointDict] }

    // we need to include un-touched endpoint metrics here
    this.tsdSubscription.metricsUsed.forEach((entry) => {
      const otherEndpointId = Object.keys(entry)[0]
      if (otherEndpointId !== endpointId) {
        const otherMetrics = entry[otherEndpointId]
        const anotherDict: Record<string, any> = {}
        anotherDict[otherEndpointId] = otherMetrics
        payload.metrics.push(anotherDict)
      }
    })

    // show busy overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateMetricsBusy)

    this.proficloud.http.post(url, payload).subscribe({
      next: (res: TsdSubscription) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateMetricsSuccess)

        // close success dialog after 2 seconds
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)

        // update local representation, re-count
        this.tsdSubscription = res
        this.setAndCountTsdMetrics()

        // console.log('set metrics success', res)
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateMetricsError)

        console.log('set metrics error', error)
      },
    })
  }

  /**
   *    User action: Modal based metrics assignment (via tsd service)
   */
  bulkAssignMetrics(payload: any) {
    // TODO: Type this properly
    const url = this.httpBase.backendUrls.tsdServiceUrl + '/billing/used/metrics/' + this.tsdSubscription.id

    // show busy overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateMetricsBusy)

    this.proficloud.http.post(url, payload).subscribe({
      next: (res) => {
        // update local representation, re-count
        this.tsdSubscription = res as any
        this.setAndCountTsdMetrics()

        // show and close success dialog after 2 seconds
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateMetricsSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)
      },
      error: (error: HttpErrorResponse) => {
        this.proficloud.logoutOnUnauthorised(error)

        console.warn('bulk metric error', error)
        const errorObject = Object.assign({ extraInfo: '' }, this.proficloud.statusOverlays.updateMetricsError)
        errorObject.extraInfo = error.error
        this.statusOverlay.showStatus(errorObject)
      },
    })
  }

  /** -> below 3 are outdated, remove once modal is available */

  selectMetric(device: DeviceInfo, metric: string) {
    if (!device.usedMetrics) {
      device.usedMetrics = []
    }
    device.usedMetrics.push(metric)
    this.setUsedMetrics(this.tsdSubscription.id, device.endpointId, device.usedMetrics)
  }

  deselectMetric(device: DeviceInfo, metric: string) {
    if (!device.usedMetrics) {
      device.usedMetrics = []
    }
    device.usedMetrics = device.usedMetrics.filter((m) => m !== metric)
    this.setUsedMetrics(this.tsdSubscription.id, device.endpointId, device.usedMetrics)
  }

  toggleMetric(device: DeviceInfo, metric: string) {
    if (!device.usedMetrics) {
      device.usedMetrics = []
    }
    if (device.usedMetrics.includes(metric)) {
      this.deselectMetric(device, metric)
    } else {
      this.selectMetric(device, metric)
    }
  }

  /**
   *    Adding / Removing devices from impulse analytics subscription
   */
  setImpulseAssignedDevices(subscriptionId: string = '600ae459fe4b747d4cfaea04', endpointIds: string[] = ['ac986689-610c-4bf6-be26-f651daf71460']) {
    const url = this.httpBase.backendUrls.iaServiceUrl + `/billing/used/devices/` + subscriptionId

    const payload = {
      devices: endpointIds,
    }
    return this.proficloud.http.post(url, payload)
  }

  /**
   *    get package helpers
   */
  getIaPackageById(id: string) {
    return this.availableIaPackages.find((pack) => pack.id === id)
  }

  getTsdPackageById(id: string) {
    return this.availableTsdPackages.find((pack) => pack.id === id)
  }

  // todo: add available emma packages
  getPackageById(id: string) {
    return [...(this.emmaPackages || []), ...(this.availableTsdPackages || []), ...(this.availableIaPackages || [])].find((pack) => pack.id === id)
  }

  countPackageById(id: string) {
    return [this.tsdSubscription, ...this.impulseAnalyticsSubscriptions].filter((sub) => sub.bookedPackageId === id).length
  }

  /**
   *    Get all user subscriptions
   */
  // Note: This is actually where we should be getting emma subscriptions from now
  listAllSubscriptions() {
    const url = this.httpBase.backendUrls.billingBaseUrl + '/subscriptions'
    return this.proficloud.http.get<SubscriptionResponse[]>(url)
  }

  /**
   *    Get all ChargeRepay subscriptions
   */
  listChargeRepaySubscriptions() {
    const url = this.httpBase.backendUrls.chargeRepayUrl + '/v1/billing/subscriptions'

    return new Observable<any>((sub) => {
      this.proficloud.http
        .get<ChargeRepaySubscriptionResponse>(url, {
          headers: new HttpHeaders(this.proficloud.getHeaderDict()),
        })
        .subscribe({
          next: (res) => {
            sub.next(res)
            sub.complete()
          },
          error: (err) => {
            //return empty array in case of http errors to avoid exceptions.
            //log exception
            console.warn('It was not possible to fetch the charge repay subscriptions from the backend. ', err)
            sub.next([])
            sub.complete()
          },
        })
    })
  }

  /**
   *    Cancel a subscription, and reload respective category
   */
  cancelSubscription(subscriptionId: string, type: ServiceIds) {
    const url = this.httpBase.backendUrls.billingBaseUrl + `/subscriptions/${subscriptionId}`

    // show busy overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelSubscriptionBusy)

    this.proficloud.http.delete<SubscriptionResponse>(url).subscribe({
      next: (cancelledSubscription) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelSubscriptionSuccess)
        setTimeout(() => this.statusOverlay.resetStatus(), 2000)

        // reload
        if (type === 'tsd') {
          this.listTsdSubscriptions().subscribe({
            next: (subscriptions: TsdSubscription[]) => {
              this.tsdSubscription = subscriptions[0]
              if (this.tsdSubscription) {
                this.setAndCountTsdMetrics()
              }
              this.tsdSubscriptionsListed$.next(true)
            },
            error: (error) => {
              // This caused strange behaviour and therefore will be commented
              // this.proficloud.logoutOnUnauthorised(error)
              this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelSubscriptionError)
              console.log('list subscriptions error', error)
            },
          })
        }

        if (type === 'ia') {
          // try to get impulse analytics subscriptions!
          this.listIaSubscriptions().subscribe({
            next: (r: IaSubscription[]) => {
              this.impulseAnalyticsSubscriptions = r
            },
            error: (error) => {
              this.proficloud.logoutOnUnauthorised(error)

              console.log('list ia sub error', error)
            },
          })
        }

        // TODO: EMMA?

        if (type === 'dmsAddonBasic') {
          // Get DMS BA subscriptions
          this.deviceBilling.fetchDeviceBillingSubscriptions()
          this.listDmsBaSubscriptions()
        }

        console.log('subscription cancelled!', cancelledSubscription)
        // subscription has been cancelled
      },
      error: (error) => {
        this.proficloud.logoutOnUnauthorised(error)

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.cancelSubscriptionError)

        console.log('error cancelling subscription!', error)

        // Exception: Billing account not found: HttpStatus = NOT FOUND
      },
    })
  }

  /**
   *  Get packages available for Smart Service with id smartServiceId
   *  Note: You do not need an access token to access this function, it is independent of a concrete user
   */
  listPackagesPerService(serviceId: 'tsd' | 'impulseanalytics' | 'emma' | 'crs' | 'ems') {
    const url = `${this.httpBase.backendUrls.billingBaseUrl}/${serviceId}/bookingpackages`

    return this.proficloud.http.get<BookingPackage[]>(url)
  }

  // TODO: This needs to be replaced by a pipe that only gets called once the necessary api calls have been made
  // Just now it's calling all the time from the template which is awful.
  getButtonDetails(service: ContentfulService, plan: ContentfulPlanVariant) {
    // EMMA specific restrictions
    const deprecatedEMMAPackges = ['1318899', '1318901', '1270205', 'EMMAXXL']
    const emmaExtensions = ['1768808', '1768809', '1768811', '1768815']
    if (plan.fields.planName === 'EMMA Service') {
      // Disable old packages
      if (deprecatedEMMAPackges.includes(plan.fields.sapNumber)) {
        return { wording: 'book package', disabled: true }
      }

      // Disable extensions if we don't already have a pro package
      if (this.emsSubscriptions && emmaExtensions.includes(plan.fields.sapNumber)) {
        const hasProPackage = this.emsSubscriptions
          .map((s) => {
            return this.getPackageById(s.bookedPackageId)?.name === 'EMS - PRO LICENCE'
          })
          .reduce((prev, curr) => {
            return prev || curr
          }, false)

        return { wording: 'book package', disabled: hasProPackage ? this.isDisabled(plan) : true }
      }

      // Disable if we already have one and multiples not allowed
      if (this.emsSubscriptions && !plan.fields.multipleOrderAllowed) {
        const hasAlready = this.emsSubscriptions
          .map((s) => {
            return this.getPackageById(s.bookedPackageId)?.id === plan.fields.sapNumber
          })
          .reduce((prev, curr) => {
            return prev || curr
          }, false)

        if (hasAlready) {
          return { wording: 'your plan', disabled: true }
        }
      }
    }

    // Impulse analytics specific restrictions
    if (plan.fields.planName === 'ImpulseAnalytics' && !this.tsdSubscription) {
      return { wording: 'not loaded', disabled: true }
    }

    // Other services (default behaviour)
    if (plan.fields.price === 0) {
      return { wording: 'go to service', disabled: false }
    }

    if (service.fields.id === 'tsd') {
      // check if this is your plan varient
      if (plan.fields.sapNumber === this.tsdSubscription.bookedPackageId) {
        return { wording: 'your plan', disabled: true }
      }
    }

    return { wording: 'book package', disabled: this.isDisabled(plan) }
  }

  private isDisabled(plan: ContentfulPlanVariant): boolean {
    return !plan.fields.preview || !this.authorization.isAdmin(this.proficloud.currentOrganisation)
  }
}
